import { render, staticRenderFns } from "./SaveFooter.vue?vue&type=template&id=36ae09ed&scoped=true&"
import script from "./SaveFooter.vue?vue&type=script&lang=ts&"
export * from "./SaveFooter.vue?vue&type=script&lang=ts&"
import style0 from "./SaveFooter.vue?vue&type=style&index=0&id=36ae09ed&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ae09ed",
  null
  
)

export default component.exports