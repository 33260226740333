import { defineComponent, watch } from '@vue/composition-api';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
export default defineComponent({
    props: {
        onRevert: {
            type: Function,
            default: () => { }
        },
        onSave: {
            type: Function,
            default: () => { }
        },
        onClose: {
            type: Function,
            default: () => { }
        },
        isChanged: {
            type: Function,
            default: () => { }
        },
        isSaved: {
            type: Function,
            default: () => { }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        page: {
            type: String,
            default: 'profile'
        }
    },
    setup(props, context) {
        const { root } = context;
        const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
        watch(() => props.isSaved(), val => {
            if (val) {
                addToast({
                    text: root.$i18n.t(`${props.page}.footer.close_label`),
                    type: 'success',
                    dismissAfter: 5000
                });
                props.onClose();
            }
        });
    }
});
